<template>
  <div class="test-container">
    输入以测试
    <ChatInput :group-members="[{aliasName:'用户1',id:1},{aliasName: '用户2',id:2},{aliasName:'用户3',id:3}]"
               :owner-id="1"/>
  </div>
</template>

<script>
import ChatInput from "@/components/chat/ChatInput";
import '../store/index'
export default {
  name: "Test",
  components: {ChatInput},
  mounted() {
    this.$store.dispatch("load")
  }
}
</script>

<style scoped>
.test-container {
  width: 100%;
  margin-top: 60%;
  height: 50%;
}
</style>